import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Message from '../Message';
import useAuthentication from '../../authenticate2';
import { Link, useNavigate } from 'react-router-dom';
import { ImSpinner2 } from "react-icons/im";

const subLink = process.env.REACT_APP_SUBSCRIBE_LINK;

const IntroCampaign = () => {
  const user = useAuthentication();
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [selectedPlanId, setSelectedPlanId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState('');

  const closeMessage = () => {
    setShowMessage(false);
  };

  const handlePlanSelection = (planId, planName) => {
    const subscribe = async (planId, planName) => {
      try {
        setIsLoading(true);
        const response = await fetch(subLink, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId: user.uid, pid: planId, name: planName }),
        });

        const data = await response.json();

        if (!response.ok) {
          setMessage('Failed to initiate payment page. Please try again later. (subscribe/failed)');
          setShowMessage(true);
          setIsLoading(false);
          return;
        }

        if (response.ok) {
          setSelectedPlan('');
          setSelectedPlanId('');
          setModalVisible(false);
          setIsLoading(false);
          window.location.replace(data.url);
        }
      } catch (error) {
        console.error(error);
        setMessage('Failed to initiate payment page. Please try again later. (subscribe/internal)');
        setShowMessage(true);
        return;
      }
    };

    if (!user) {
      navigate('/register');
      return;
    }

    if(!selectedPlanId && !selectedPlan)
    {
      setSelectedPlan(planName);
      setSelectedPlanId(planId);
      setModalVisible(true);
      return;
    }

    subscribe(planId, planName);
  };

  const closeModal = () =>
  {
    setSelectedPlan('');
    setSelectedPlanId('');
    setModalVisible(false);
  }

  const PlanSelectionModal = ({ plan, planId }) => (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white text-black rounded-lg p-6 w-96">
        <h2 className="text-2xl font-bold mb-4">Choose Billing Cycle</h2>
        <p className="mb-6">Please select the billing cycle for the {plan} plan:</p>
        <div className="grid gap-4">
          <button className="bg-yellow-500 hover:bg-yellow-600 text-white font-semibold px-4 py-2 rounded-lg focus:outline-none focus:ring focus:border-blue-300 transition hover:scale-105 shadow" onClick={() => {
            setLoading('1');
            handlePlanSelection(planId + '_annual', plan);
            }}>
            {isLoading && loading === '1' ? (
            <ImSpinner2 className="animate-spin mx-auto text-2xl" />
          ) : (
            planId === 'starter' ? (
              <>
                {plan} Annually (€4.99/month*)
              </>
            ) : (
              `${plan} Annually (€19.99/month*)`
            )
          )}</button>
          <button className="bg-blue-600 hover:bg-blue-700 text-white font-semibold px-4 py-2 rounded-lg focus:outline-none focus:ring focus:border-blue-300" onClick={() => {
            setLoading('2');
            handlePlanSelection(planId, plan);
            }}>
            {isLoading && loading === '2' ? (
            <ImSpinner2 className="animate-spin mx-auto text-2xl" />
          ) : (
            planId === 'starter' ? (
              <>
                {plan} Monthly (€6.99/month*)
              </>
            ) : (
              `${plan} Monthly (€26.99/month*)`
            )
          )}</button>
        </div>
        <button className="mt-4 text-gray-500 hover:underline" onClick={() => closeModal()}>Cancel</button>
      </div>
    </div>
  );

  return (
    <>
      <div>
        <Helmet>
          <title>Plans - Stenz Digital</title>
        </Helmet>
      </div>
      <div className="flex flex-col justify-center items-center min-h-screen text-white">
        <div className="w-full sm:max-w-md md:max-w-lg lg:max-w-2xl xl:max-w-3xl">
          {showMessage ? (
            <Message message={message} onClose={closeMessage} />
          ) : (
            <div className="flex flex-col justify-center items-center min-h-screen p-4">
              <div className="w-full sm:max-w-md md:max-w-lg lg:max-w-2xl xl:max-w-3xl">
                {showMessage && <Message message={message} onClose={closeMessage} />}
                <h1 className="text-4xl font-semibold py-6 mt-10 lg:mt-0 mb-6">Choose Your Plan</h1>
                <div className="bg-yellow-500 text-white rounded-lg p-4 mb-8 shadow-lg">
                  <h2 className="text-2xl font-bold">Introductory Offer!</h2>
                  <p className="mt-2 text-lg">Get 50% off on the Starter plan. Use code <span className="font-bold">INTRO</span> at checkout.</p>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-1 xl:grid-cols-3 gap-8">
                  <div className="bg-gray-900 text-white rounded-lg p-8 flex flex-col shadow-lg hover:shadow-xl transform hover:scale-105 transition-transform">
                    <h2 className="text-2xl font-bold mb-4">Starter</h2>
                    <div className="mb-6">
                      <p className="text-gray-300 text-lg">
                        <span className="line-through">€9.99/month*</span> <span className="text-yellow-500">€4.99/month*</span>
                      </p>
                      <hr className="border-t border-gray-600 my-4" />
                      <p className="text-gray-300">
                        - 1 site included<br />
                        - Free hosting<br />
                        - Free SSL certificate<br />
                        - <span className="font-bold underline hover:text-blue-400 cursor-pointer">
                          <Link to={'/plans/starter'} target='_blank' rel='noopener noreferrer'>
                            View full details
                          </Link>
                        </span>
                      </p>
                    </div>
                    <div className="mt-auto flex justify-between gap-4">
                      <button className="bg-blue-600 hover:bg-blue-700 text-white font-semibold px-4 py-2 rounded-lg focus:outline-none focus:ring focus:border-blue-300" onClick={() => handlePlanSelection("starter", "Starter")}>Select Plan</button>
                      <button className="bg-blue-600 hover:bg-blue-700 text-white font-semibold px-4 py-2 rounded-lg focus:outline-none focus:ring focus:border-blue-300" onClick={() => window.open('https://stenzdigital.app/plans/starter', '_blank')}>Learn More</button>
                    </div>
                  </div>
                  <div className="bg-blue-700 text-white rounded-lg p-8 flex flex-col transform scale-105 shadow-lg hover:shadow-xl hover:scale-110 transition-transform">
                    <h2 className="text-3xl font-bold mb-4">Pro</h2>
                    <div className="mb-6">
                      <p className="text-gray-200 text-lg">€19.99/month*</p>
                      <hr className="border-t border-gray-200 my-4" />
                      <p className="text-gray-200">
                        - 2 sites included<br />
                        - Free hosting<br />
                        - Free SSL certificate<br />
                        - <span className="font-bold underline hover:text-yellow-300 cursor-pointer">
                          <Link to={'/plans/pro'} target='_blank' rel='noopener noreferrer'>
                            View full details
                          </Link>
                        </span>
                      </p>
                    </div>
                    <div className="mt-auto flex justify-between gap-4">
                      <button className="bg-yellow-500 px-4 py-2 hover:bg-yellow-600 text-white font-bold rounded-lg focus:outline-none focus:ring focus:border-yellow-300" onClick={() => handlePlanSelection("pro", "Pro")}>Select Plan</button>
                      <button className="bg-yellow-500 px-4 py-2 hover:bg-yellow-600 text-white font-bold rounded-lg focus:outline-none focus:ring focus:border-yellow-300" onClick={() => window.open('https://stenzdigital.app/plans/pro', '_blank')}>Learn More</button>
                    </div>
                  </div>
                  <div className="bg-gray-900 text-white rounded-lg p-8 flex flex-col shadow-lg hover:shadow-xl transform hover:scale-105 transition-transform">
                    <h2 className="text-2xl font-bold mb-4">Enterprise</h2>
                    <div className="mb-6">
                      <p className="text-gray-300 text-lg">Contact Sales</p>
                      <hr className="border-t border-gray-600 my-4" />
                      <p className="text-gray-300">
                        - 100% Tailored<br />
                        - Custom pricing<br />
                        - <span className="font-bold underline hover:text-blue-400 cursor-pointer">
                          <Link to={'/plans/enterprise'} target='_blank' rel='noopener noreferrer'>
                            View full details
                          </Link>
                        </span>
                      </p>
                    </div>
                    <div className="mt-auto flex justify-between gap-4">
                      <button className="bg-blue-600 hover:bg-blue-700 text-white font-semibold px-4 py-2 rounded-lg focus:outline-none focus:ring focus:border-blue-300" onClick={() => window.open('https://stenzdigital.app/plans/enterprise', '_blank')}>Select Plan</button>
                      <button className="bg-blue-600 hover:bg-blue-700 text-white font-semibold px-4 py-2 rounded-lg focus:outline-none focus:ring focus:border-blue-300" onClick={() =>  window.open('https://stenzdigital.app/plans/enterprise', '_blank')}>Learn More</button>
                    </div>
                  </div>
                </div>
                <p className="text-gray-500 text-sm mt-6">*Prices may vary based on subscription duration, site usage and location. Final price will be calculated at checkout.</p>
              </div>
            </div>
          )}
        </div>
        {modalVisible && <PlanSelectionModal plan={selectedPlan} planId={selectedPlanId} />}
      </div>
    </>
  );
};

export default IntroCampaign;
