import React, { useEffect, useState } from 'react';
import useAuthentication from '../authenticate2';
import { useLocation, useNavigate } from 'react-router-dom';

const PricingBanner = () => {
  const [showBanner, setShowBanner] = useState(false);
  const user = useAuthentication();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const paths = ['/plans', '/legal', '/register', '/campaigns'];
    if (!user && !paths.some(path => location.pathname.includes(path))) {
        setShowBanner(true);
    } else {
        setShowBanner(false);
    }
}, [user, location]);

  const handleViewPricing = async () => {
    navigate('/plans');
  }

  return (
    <>
      {showBanner && (
        <div onClick={handleViewPricing} className="absolute z-10 bottom-5 left-1/2 transform -translate-x-1/2 bg-blue-500 text-white p-4 rounded-full shadow-md animate-fade-jump-up hover:scale-105 cursor-pointer duration-300">
          <p
            to={'/plans'}
            className="text-lg font-semibold">
            View Pricing
          </p>
        </div>
      )}
    </>
  );
};

export default PricingBanner;
