import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
//import Welcome from './components/Welcome';
//import Home from './components/Home';
import Header from './components/Header';
import MobileHeader from './components/MobileHeader';
import Footer from './components/Footer';
import Login from './components/Login';
import Register from './components/Register';
import Sites from './components/Sites';
import Email from './components/Email';
import Ads from './components/Ads';
import Account from './components/Account';
import Site from './components/Site';
import NotFound from './components/NotFound';
import Ad from './components/Ad';
import Billing from './components/Billing';
import KYC from './components/CustomerVerification';
import Logout from './components/Logout';
import VerifyEmail from './components/VerifyEmail';
import EmailHandler from './components/EmailHandler';
import { useMediaQuery } from '@react-hook/media-query';
import ResetPassword from './components/ResetPassword';
import ChangePassword from './components/ChangePassword';
import Settings from './components/Settings';
import SiteDetails from './components/SiteDetails';
import Editor from './components/Editor';
import SitePreview from './components/SitePreview';
import CookieBanner from './components/CookieBanner';
import DomainManagement from './components/DomainManagement';
import EmailVerificationNotice from './components/EmailVerificationNotice';
//import Plans from './components/Plans';
import Subscribed from './components/Subscribed';
//import SecuritySettings from './components/SecuritySettings';
import SiteDashboard from './components/SiteDashboard';
import ViewProducts from './components/ViewProducts';
import ViewSingleProduct from './components/ViewSingleProduct';
import ReauthOnboarding from './components/ReauthOnboarding';
import Legal from './components/Legal';
import PrivacyPolicy from './components/legal/PrivacyPolicy';
import TOS from './components/legal/TOS';
import Contact from './components/Contact';
import StarterPlan from './components/plans/StarterPlan';
import ProPlan from './components/plans/ProPlan';
import EntPlan from './components/plans/EntPlan';
import PricingBanner from './components/PricingBanner';
import IntroCampaign from './components/campaigns/IntroCampaign';

const App = () => {
const isMobile = useMediaQuery('(max-width: 768px)');  

  return (
    <Router>
      <div className="bg-gray-800">
        {isMobile ? <MobileHeader /> : <Header />}
        <EmailVerificationNotice />
        <PricingBanner />
        <Routes>
          <Route path='*' element={<NotFound />} />
          <Route path="/" element={<Navigate to={'/login'} />} />
          <Route path="/home" element={<Navigate to={'/sites'} />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/sites" element={<Sites />} />
          <Route path="/email" element={<Email />} />
          <Route path="/ads" element={<Ads />} />
          <Route path="/account" element={<Account />} /> 
          {/*<Route path="/account/security" element={<SecuritySettings />} /> */}
          <Route path="/sites/:sn/:sid" element={<Site />} />
          <Route path="/sites/:sn/:sid/settings" element={<Settings />} />
          <Route path="/sites/:sn/:sid/details" element={<SiteDetails />} />
          <Route path="/sites/:sn/:sid/domain" element={<DomainManagement />} />
          <Route path="/sites/:sn/:sid/edit" element={<Editor />} />
          <Route path="/sites/:sn/:sid/preview" element={<SitePreview />} />
          <Route path="/sites/:sn/:sid/dashboard" element={<SiteDashboard />} />
          <Route path="/sites/:sn/:sid/dashboard/products" element={<ViewProducts />} />
          <Route path="/sites/:sn/:sid/dashboard/products/:pid" element={<ViewSingleProduct />} />
          <Route path="/ads/:aid" element={<Ad />} />
          <Route path="/billing" element={<Billing />} />
          {/*<Route path="/plans" element={<Plans />} />*/}
          <Route path="/plans" element={<IntroCampaign />} />
          <Route path="/plans/starter" element={<StarterPlan />} />
          <Route path="/plans/pro" element={<ProPlan />} />
          <Route path="/plans/enterprise" element={<EntPlan />} />
          <Route path="/p/subscribed" element={<Subscribed />} />
          <Route path="/kyc" element={<KYC />} />
          <Route path='/logout' element={<Logout />} />
          <Route path="/e/verify" element={<VerifyEmail />} />
          <Route path="/e/reset" element={<ChangePassword />} />
          <Route path="/e" element={<EmailHandler />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/onboarding/reauth" element={<ReauthOnboarding />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/legal/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/legal/tos" element={<TOS />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <CookieBanner />
        <Footer />
      </div>
    </Router>
  );
};

export default App;