import { useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import auth from './firebase';
import { useLocation, useNavigate } from 'react-router-dom';
import useUpdateAuth from './updateAuth';

const useAuthentication = () => {
  const [user, setUser] = useState(null);
  useUpdateAuth();

  const apiLink = process.env.REACT_APP_API_LINK

  const navigate = useNavigate();
  const location = useLocation();

  const fetchUser = async (user) => {
    // Fetch additional user information from backend
    const response = await fetch(apiLink + 'getUser', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId: user.uid }),
    });

    if (!response.ok) {
      return;
    }

    if (response.ok) {
      const userData = await response.json();
      // Merge userData fields into user object
      const updatedUser = { ...user, ...userData };
      setUser(updatedUser);
    }
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
        console.log('User is signed in');
        if (['/', '/login', '/register'].includes(location.pathname)) {
          navigate('/home');
        }
        await fetchUser(user);
      } else {
        setUser(null);
        console.log('User is not signed in');
        if (!['/', '/login', '/register', '/404', '/e','/e/verify', '/reset-password'].includes(location.pathname)) {
          navigate('/');
        }
      }
    });

    // Cleanup function
    return () => unsubscribe();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, location.pathname]);

  return user;
};

export default useAuthentication;
