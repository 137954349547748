import { useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import auth from './firebase';
import { useLocation } from 'react-router-dom';

const useAuthentication = () => {
  const [user, setUser] = useState(null);

  const location = useLocation();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    // Cleanup function
    return () => unsubscribe();
  }, [location.pathname]);

  return user;
};

export default useAuthentication;
