import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useAuthentication from '../authenticate';
import { Puck } from "@measured/puck";
import "@measured/puck/puck.css";
import config from '../editorConfig';
import eConfig from '../eEditorConfig';
import Message from './Message';
import { useMediaQuery } from '@react-hook/media-query';

const Editor = () => {
const { sn, sid } = useParams();
const user = useAuthentication();
const [loading, setLoading] = useState(true);
const [siteInfo, setSiteInfo] = useState(null);
const [showDefault, setShowDefault] = useState(true);
const [showMessage, setShowMessage] = useState(false);
const [goHome, setGoHome] = useState(true);
const [message, setMessage] = useState('');
const [template, setTemplate] = useState('');
const isMobile = useMediaQuery('(max-width: 768px)');  
const [showAddPagesModal, setShowAddPagesModal] = useState(false);
const [showRemovePageModal, setShowRemovePageModal] = useState(false);
const [pageTitle, setPageTitle] = useState('');
const [pageUrl, setPageUrl] = useState('');
const [pageToRemove, setPageToRemove] = useState('');
const [key, setKey] = useState(0);
const [domain, setDomain] = useState('');
const [published, setPublished] = useState(false);

 const [pages, setPages] = useState([
  { pageId: 'home', pageTitle: 'Home', pagePath: '/' },
]);
const [currentPage, setCurrentPage] = useState({ pageId: 'home', pageTitle: 'Home', pagePath: '/' });
const [pageInfo, setPageInfo] = useState(null);

const siteEditor = process.env.REACT_APP_SITE_EDITOR_LINK

const navigate = useNavigate();

const getDefaultSiteInfo = async () => {
  if(siteInfo)
  {
    return;
  }
  try {

    const sites = user.user.sites;
    const siteFound = sites.find(site => site.siteId === sid);

    const response = await fetch(siteEditor + 'getSiteDefault', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ userId: user.uid, siteId: sid, template: siteFound?.template})
    });

    if (!response.ok) {
      setLoading(false);
      return;
    }
    const data = await response.json();
    const structuredData = structureSiteInfo(data.siteInfo);
    if(data.pages)
    {
      const mappedPages = data.pages.map(page => ({
        pageId: page.pageId,
        pageTitle: page.pageTitle,
        pagePath: page.pagePath,
        siteInfo: structureSiteInfo(page.siteInfo)
      }));
  
      //Initial data
      const info = [
        { pageId: 'home', pageTitle: 'Home', pagePath: '/', siteInfo: structuredData },
        ...mappedPages
      ];
      setPageInfo(info);
      setPages(info);
    }
    setSiteInfo(structuredData);
    setShowDefault(false);
    setLoading(false);
  } catch (error) {
    console.error('Error getting site data:', error);
  }
}

const getSiteInfo = async () => {
  if (siteInfo) {
    return;
  }

  if (!user || !user.user || !user.user.sites) {
    return;
  }

  const sites = user.user.sites;
  const siteFound = sites.find(site => site.siteId === sid);
  const template = siteFound?.template;
  const domain = siteFound?.customDomain ? siteFound?.customDomain : siteFound?.domain;
  const published = siteFound?.siteInfo ? true : false;
  setTemplate(template);
  setDomain(domain);
  setPublished(published);

  try {
    const response = await fetch(siteEditor + 'getSite', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ userId: user.uid, siteId: sid })
    });

    if (!response.ok) {
      getDefaultSiteInfo();
      return;
    }

    const data = await response.json();
    const structuredData = structureSiteInfo(data.siteInfo);
    if(data.pages)
    {
      const mappedPages = data.pages.map(page => ({
        pageId: page.pageId,
        pageTitle: page.pageTitle,
        pagePath: page.pagePath,
        siteInfo: structureSiteInfo(page.siteInfo)
      }));

      //Initial data
      const info = [
        { pageId: 'home', pageTitle: 'Home', pagePath: '/', siteInfo: structuredData },
        ...mappedPages
      ];
      setPageInfo(info);
      setPages(info);
    }
    else
    {
      setPages([{ id: 'home', title: 'Home', path: '/' }]);
    }
    setSiteInfo(structuredData);
    setShowDefault(false);
    setLoading(false);
  } catch (error) {
    console.error('Error getting site data:', error);
  }
};

const structureSiteInfo = (siteInfo) => {
  if (!siteInfo || !siteInfo.content || !siteInfo.root) {
    return null;
  }

  const structuredData = {
    content: siteInfo.content.map(item => ({
      type: item.type,
      props: { ...item.props }
    })),
    root: { ...siteInfo.root }
  };

  return structuredData;
};

useEffect(() => {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  if (isSafari) {
    setShowMessage(true);
    setMessage("Safari is currently not supported. Please use a different browser. (editor/not-supported)");
  }
  else if (isMobile){
    setShowMessage(true);
    setMessage("Mobile is currently not supported. Please use a PC. (editor/not-supported)");
  } 
  else {
    if (user) {
      getSiteInfo();
    }
  }
  //eslint-disable-next-line react-hooks/exhaustive-deps
}, [user]);

const save = async (data) => {
  try {
    const response = await fetch(siteEditor + 'setSite', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(!pages.length > 1 ? {userId: user.uid, siteId: sid, siteInfo: data} : {userId: user.uid, siteId: sid, siteInfo: data, pageId: currentPage.pageId, pageTitle: currentPage.pageTitle, pagePath: currentPage.pagePath})
    });

    const res = await response.json();

    if (res.error) {
      setGoHome(false);
      setMessage(`Failed to publish site (${res.error})`);
      setShowMessage(true);
      return;
    }

    setGoHome(false);
    setMessage('Successfully published site!');
    setShowMessage(true);
    if (pages.length > 1) {
      const updatedPages = pages.map(page => {
        if (page.pageId === currentPage.pageId) {
          return {
            ...page,
            siteInfo: data
          };
        }
        return page;
      });
    
      // Check if 'home' page is present in the updatedPages array
      const homePageExists = updatedPages.some(page => page.pageId === 'home');
    
      // If 'home' page is not present, add it to the updatedPages array
      if (!homePageExists) {
        const homePage = pageInfo.find(page => page.pageId === 'home');
        if (homePage) {
          updatedPages.unshift(homePage);
        }
      }
    
      // Update state with the modified pages array
      setPages(updatedPages);
      setPageInfo(updatedPages);
      setSiteInfo(data);
      setKey(prevKey => prevKey + 1);
      return;
    }
    const updatedPages = pages.map(page => {
      if (page.pageId === currentPage.pageId) {
        return {
          ...page,
          siteInfo: data
        };
      }
      return page;
    });
    setPages(updatedPages);
    setPageInfo(updatedPages);
    setSiteInfo(data);
    setKey(prevKey => prevKey + 1);
  } catch (error) {
    console.error('Error saving site data:', error);
  }
};

const handleCloseMessage = () => {
  setShowMessage(false);
  if(goHome === false)
  {
    setGoHome(true);
    return;
  }
  if(template === 'e-commerce')
  {
    navigate(`/sites/${sn}/${sid}/dashboard`);
    return;
  }
  navigate(`/sites/${sn}/${sid}`);
};

const handleUpdateData = async (pageId, pageTitle) => {
  const existingPage = pageInfo.find(page => page.pageId === pageId);
    
    if (!existingPage || !existingPage.siteInfo) {
      const blankSiteInfo = {
        content: [],
        root: {
          props: {
            title: pageTitle
          }
        },
        zones: {}
      };

      setSiteInfo(blankSiteInfo);
    }

    if (existingPage && existingPage.siteInfo) {
      setSiteInfo(existingPage.siteInfo);
    }

    setKey((prevKey) => prevKey + 1);
  };

const navigateToPage = async (pageId, pageTitle, pagePath) => {
  setCurrentPage({ pageId, pageTitle, pagePath });
  await handleUpdateData(pageId, pageTitle);
};

const handleCloseAddPagesModal = () => {
  setShowAddPagesModal(false);
};

const handleAddPage = () => {

  const titleExists = pages.some(page => page.pageTitle === pageTitle);
  const urlExists = pages.some(page => page.pagePath === pageUrl);

  if(titleExists || urlExists)
  {
    const titleMessage = titleExists ? 'title' : '';
    const urlMessage = urlExists ? 'url' : '';
    setMessage(`A page with that ${titleMessage}${titleExists && urlExists ? ' and ' : ''}${urlMessage} already exists.`)
    setShowMessage(true);
    setGoHome(false);
    handleCloseAddPagesModal();
    setPageTitle('');
    setPageUrl('');
    return;
  }
  const newPage = { 
    pageId: pageTitle + Math.floor(Math.random() * 99999), 
    pageTitle: pageTitle, 
    pagePath: pageUrl 
  };
  setPages([...pages, newPage]);
  handleCloseAddPagesModal();
  setPageTitle('');
  setPageUrl('');
};

const removePage = async (pageToRemove) => {
  try {
    const response = await fetch(siteEditor + 'deletePage', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({userId: user.uid, siteId: sid, pageId: pageToRemove})
    });

    if (!response.ok) {
      throw new Error('Failed to delete page.');
    }
    
    setGoHome(false);
    setMessage('Successfully deleted page!');
    navigateToPage('home', 'Home', '/');
    setShowMessage(true);
  } catch (error) {
    console.error('Error deleting page:', error);
  }
}

 const handleRemovePage = async () => {

  if (!user || !user.user || !user.user.sites) {
    return;
  }

  const sites = user.user.sites;
  const siteFound = sites.find(site => site.siteId === sid);
  const oPages = siteFound?.pages;

  if (!oPages || !oPages.some(page => page.pageId === pageToRemove)) {
    const updatedPages = pages.filter(page => page.pageId !== pageToRemove);
    setPages(updatedPages);
    setShowRemovePageModal(false);
    setPageToRemove('');
    setGoHome(false);
    setMessage('Successfully deleted page!');
    navigateToPage('home', 'Home', '/');
    setShowMessage(true);
    return;
  }

  const updatedPages = pages.filter(page => page.pageId !== pageToRemove);
  setPages(updatedPages);
  setShowRemovePageModal(false);
  await removePage(pageToRemove);
  setPageToRemove('');
};

const overrides = {
  headerActions: ({ children }) => (
    <>
      {children}
      {template === 'e-commerce' && (
        <>
        <div className="grid grid-cols-1 gap-4 xl:grid-cols-3 xl:gap-6 overflow-y-auto max-h-20 w-2/3">
          {pages.map((page) => (
            <div key={page.pageId} className="rounded-lg shadow-md border border-gray-200 overflow-hidden">
              <button
                className={`block w-full px-3 py-2 text-xs xl:text-sm text-gray-700 hover:bg-gray-100 focus:outline-none overflow-hidden ${currentPage.pageId === page.pageId ? 'bg-blue-500 text-white' : ''}`}
                onClick={() => navigateToPage(page.pageId, page.pageTitle, page.pagePath)}
              >
                {page.pageTitle}
              </button>
            </div>
          ))}
        </div>
        <div className='flex justify-between'>
          <button
            className="border-2 border-green-500 rounded-md sm:p-1 sm:text-xs xl:text-sm text-green-500 hover:bg-green-500 hover:text-white focus:outline-none mr-2"
            onClick={() => setShowAddPagesModal(true)}
          >
            + Add Page
          </button>
          <button
            className="border-2 border-red-500 rounded-md sm:p-1 sm:text-xs xl:text-sm text-red-500 hover:bg-red-500 hover:text-white focus:outline-none ml-2"
            onClick={() => setShowRemovePageModal(true)}
          >
            - Delete Page
          </button>
        </div>
      </>
      )}
      {published && (
        <Link to={domain + currentPage.pagePath} target='_blank'>
          <p className='border-2 border-blue-500 rounded-md py-2 px-1 xl:py-1 text-sm text-black hover:bg-blue-500 hover:text-white focus:outline-none text-center'>View Site</p>
        </Link>
      )}
      <Link to={template === 'e-commerce' ? `/sites/${sn}/${sid}/dashboard` : `/sites/${sn}/${sid}`}>
        <p className='border-2 border-black rounded-md p-2 text-sm text-black hover:bg-black hover:text-white focus:outline-none'>Back</p>
      </Link>
    </>
  ),
};

return (
  <div className="flex justify-center items-center h-screen">
    <Helmet>
      <title>Editor - Stenz Digital</title>
    </Helmet>
      {showMessage ? (
        <Message
          message={message}
          onClose={handleCloseMessage}
        />
      ) : (
        <>
          <div className="bg-gray-900 rounded-lg shadow-lg p-8">
          <header className="text-3xl font-semibold text-gray-300 mb-4">Editor</header>
          {loading ? (
            <div className="mb-4">
              <div className="animate-pulse bg-gray-600 p-4 rounded-lg mb-2" style={{ width: '200px', height: '20px' }}></div>
              <div className="animate-pulse bg-gray-600 p-4 rounded-lg mb-2" style={{ width: '300px', height: '20px' }}></div>
            </div>
          ) : (
             <>
              {!showDefault ? (
                <>
                  <Puck key={key} config={template === 'e-commerce' ? eConfig : config} data={siteInfo} onPublish={save} overrides={overrides}/>
                </>
              ) : (
                <>
                  <Puck key={key} config={template === 'e-commerce' ? eConfig : config} onPublish={save} overrides={overrides}/>
                </>
              )}
             </>
          )}
          </div>
        </>
      )}

      {showAddPagesModal && (
        <div className="fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-gray-800 rounded-lg p-8 text-white w-96">
          <h2 className="text-2xl font-semibold mb-4">Add Page</h2>
          <form onSubmit={handleAddPage}>
            <div className="mb-4">
              <label htmlFor="pageTitle" className="block text-sm font-medium mb-2">Page Title</label>
              <input
                type="text"
                id="pageTitle"
                value={pageTitle}
                onChange={(e) => setPageTitle(e.target.value)}
                className="bg-gray-700 rounded-lg w-full py-2 px-4 text-white placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                placeholder="Enter page title"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="pageUrl" className="block text-sm font-medium mb-2">Page URL</label>
              <input
                type="text"
                id="pageUrl"
                value={pageUrl}
                onChange={(e) => setPageUrl(e.target.value)}
                className="bg-gray-700 rounded-lg w-full py-2 px-4 text-white placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                placeholder="Enter page URL (e.g. /products, /contact)"
                required
              />
            </div>
            <div className="flex justify-end">
              <button type="button" onClick={handleCloseAddPagesModal} className="mr-4 bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-blue-300">Cancel</button>
              <button type="submit" className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-blue-300">Add Page</button>
            </div>
          </form>
        </div>
      </div>
      )}

      {showRemovePageModal && (
        <div className="fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-gray-800 rounded-lg p-8 text-white w-96">
          <h2 className="text-2xl font-semibold mb-4">Delete Page</h2>
          <form>
            <div className="mb-4">
              <label htmlFor="pageToRemove" className="block text-sm font-medium mb-2">Select Page to Delete</label>
              <select
                id="pageToRemove"
                value={pageToRemove}
                onChange={(e) => setPageToRemove(e.target.value)}
                className="bg-gray-700 rounded-lg w-full py-2 px-4 text-white placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
              >
                <option value="" disabled>Select a page to delete</option>
                {pages.map(page => (
                page.pageId !== 'home' && (
                  <option key={page.pageId} value={page.pageId}>{page.pageTitle}</option>
                )
              ))}
              </select>
            </div>
            <p className='bg-red-500 text-lg text-center mb-4'>This action is irreversible.</p>
            <div className="flex justify-end">
              <button type="button" onClick={() => setShowRemovePageModal(false)} className="mr-4 bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-blue-300">Cancel</button>
              <button type="button" onClick={handleRemovePage} className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-blue-300">Delete Page</button>
            </div>
          </form>
        </div>
      </div>
      )}
  </div>
);
};

export default Editor;