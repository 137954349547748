import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, Link, useNavigate } from 'react-router-dom';
import useAuthentication from '../authenticate';
import Message from './Message';

const getStatusLink = process.env.REACT_APP_SITE_EDITOR_LINK;


const Site = () => {
  const user = useAuthentication();
  const { sn, sid } = useParams();
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [commerceTools, setCommerceTools] = useState(false);
  const [message, setMessage] = useState('');
  const [customDomain, setCustomDomain] = useState('');
  const [hasCustomDomain, setHasCustomDomain] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if(user && !user.emailVerified)
    {
      setMessage('Please verify your email address to unlock all features.');
      setShowMessage(true);
    }

    const fetchUserSites = async () => {
      try {
        if (!user || !user.user || !user.user.sites) {
          return;
        }
    
        const sites = user.user.sites;
        const siteFound = sites.find(site => site.siteId === sid);
    
        if (!siteFound) {
          setError(true);
          setMessage("Couldn't find your site. Please try again later. (site/not-found)");
          setShowMessage(true);
        }

        if (siteFound && siteFound.template && siteFound.template === 'e-commerce')
        {
          setCommerceTools(true);
        }

        if (siteFound && siteFound?.customDomain)
        {
          setCustomDomain(siteFound?.customDomain);
          setHasCustomDomain(true);
        }
      } catch (error) {
        setError(true);
        setMessage("Couldn't find your site. Please try again later. (site/not-found)");
        setShowMessage(true);
      }
    };
  
    const fetchStatus = async () => {
      if (!user || !user.user || !user.user.sites) {
        return;
      }
  
      const sites = user.user.sites;
      const siteFound = sites.find(site => site.siteId === sid);

      if(!siteFound?.hosted)
      {
        setStatus('Connection error (site/failed-to-connect)');
        setLoading(false);
        setError(true);
        setMessage('Failed to connect to your site. Please try again later. (site/failed-to-connect)');
        setShowMessage(true);
        return;
      }

      try {
        const response = await fetch(getStatusLink + 'getStatus', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ siteId: sid, userId: user.uid }),
        });
        if (!response.ok) {
          setStatus('Connection error (site/failed-to-connect)');
          setLoading(false);
          setError(true);
          setMessage('Failed to connect to your site. Please try again later. (site/failed-to-connect)');
          setShowMessage(true);
          return;
        }
        const data = await response.json();
        if(data.status && sn === siteFound.name)
        {
          setStatus(data.status);
          setLoading(false);
          return;
        }
        else
        {
          setStatus('Connection error (site/failed-to-connect)');
          setError(true);
          setLoading(false);
          return;
        }
      } catch (error) {
        console.error('Error fetching site status:', error);
      }
    };
  
    // Fetch status initially
    fetchStatus();
    fetchUserSites();
  }, [sid, sn, status, error, user]);
  
  const handleCloseMessage = () => {
    setShowMessage(false);
    if(user && !user.emailVerified)
    {
      navigate('/home');
    }
    else
    {
      navigate('/sites')
    }
  };

  const handleViewSite = async () => {
    if(status === 'Published')
    {
      if(hasCustomDomain && customDomain)
      {
        window.open(customDomain, '_blank');
        return;
      }
      window.open(`https://${sn}.stnz.app`, '_blank');
    }
  }

  const handleGoToSettings = async () => {
    navigate(`/sites/${sn}/${sid}/settings`);
  }

  const handleEditSite = async () => {
    if(!commerceTools)
    {
      navigate(`/sites/${sn}/${sid}/edit`);
    }
    else
    {
      navigate(`/sites/${sn}/${sid}/dashboard`);
    }
  }

  return (
    <div className="flex justify-center items-center h-screen text-white">
      <Helmet>
        <title>{customDomain ? customDomain.replace('https://', '') : sn } - Stenz Digital</title>
      </Helmet>
      {showMessage && (
      <Message
        message={message}
        onClose={handleCloseMessage}
      />
    )}
    {!showMessage && (
      <div className="bg-gray-900 rounded-lg shadow-lg p-8 text-white">
      <header className="text-3xl font-semibold text-gray-300 mb-4">Site Management</header>
      <div className="mb-4">
        {loading ?
          <div className="animate-pulse bg-gray-600 rounded-lg mb-2" style={{ width: '200px', height: '25px' }}>
          </div>  :
          <p className="text-xl text-gray-400">
          <Link
            target={status === 'Published' ? `_blank` : ''}
            to={status === 'Published' ? (customDomain ? customDomain : `https://${sn}.stnz.app`) : ''}
            className={`font-bold underline hover:text-blue-500 ${status !== 'Published' && 'cursor-not-allowed'}`}
          >
            {customDomain ? customDomain.replace('https://', '') : sn }
          </Link>
        </p>
        }
        <div className='flex items-center mb-4 text-xl text-gray-400'>
        <p>Status:</p>
          {loading ? 
          <div className="ml-2">
          <div className="animate-pulse bg-gray-600 rounded-lg mb-2" style={{ width: '200px', height: '25px' }}>
          </div>
          </div> : 
          <div className='ml-2'>
            <p className={`${status === 'Published' ? 'text-green-400' : status === 'Unpublished' ? 'text-yellow-400' : 'text-red-400'}`}>{status}</p>
          </div>}
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div className="p-4">
          <div className={`bg-gray-600 p-4 rounded-lg text-gray-300 hover:bg-gray-700 ${status !== 'Published' ? 'cursor-not-allowed' : 'cursor-pointer'}`} onClick={status === 'Published' ? handleViewSite : null}>View Site</div>
        </div>
        <div className="p-4">
          {loading ? 
          <div className={`bg-gray-600 p-4 rounded-lg text-gray-300 animate-pulse`} style={{ width: '100px', height: '55px' }}></div>
           :  
          <div className={`bg-gray-600 p-4 rounded-lg text-gray-300 hover:bg-gray-700 ${status !== 'Published' && status !== 'Unpublished' ? 'cursor-not-allowed' : 'cursor-pointer'}`} onClick={status === 'Published' || status === 'Unpublished' ? handleEditSite : null}>{commerceTools ? 'Dashboard' : 'Edit Site'}</div>
          }
        </div>
        <div className="p-4">
          <div className={`bg-gray-600 p-4 rounded-lg text-gray-300 hover:bg-gray-700 cursor-pointer`} onClick={handleGoToSettings}>Settings</div>
        </div>
        <div className="p-4">
          <div className={`bg-gray-600 p-4 rounded-lg text-gray-300 hover:bg-gray-700 cursor-not-allowed`}>Insights</div>
        </div>
      </div>
      <div className='pt-2'>
        <Link to={'/sites'}>
          <p className='font-bold underline hover:text-blue-500'>Back</p>
        </Link>
      </div>
    </div>
    )}
    </div>
  );
};

export default Site;
