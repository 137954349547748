import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BsPlus } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import useAuthentication from '../authenticate';
import Message from './Message';
import BadWordsFilter from 'bad-words';

const apiLink = process.env.REACT_APP_API_LINK;

const Sites = () => {
  const user = useAuthentication();

  const [loading, setLoading] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [showCreateSiteModal, setShowCreateSiteModal] = useState(false);
  const [siteName, setSiteName] = useState('');
  const [siteId, setSiteId] = useState('');
  const [template, setTemplate] = useState('');
  const [error, setError] = useState(false);
  const [userSites, setUserSites] = useState([]);
  const [siteCreated, setSiteCreated] = useState(false);

  const navigate = useNavigate();

  const fetchUserSites = async (user) => {
    try
    {    
      // Fetch additional user information from backend
      const response = await fetch(apiLink + 'getSites', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: user.uid }),
      });

      if (!response.ok) {
        setLoading(false);
        return;
      }

      if (response.ok) {
        const sites = await response.json();
        setUserSites(sites.sites);
        setLoading(false);
      }
    }
    catch (error)
    {
      console.error(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (user) {
      if (!user.emailVerified) {
        setMessage('Please verify your email address to unlock all features.');
        setShowMessage(true);
      } else {
        fetchUserSites(user);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleCloseMessage = () => {
    setShowMessage(false);
    if (siteCreated) {
      setSiteCreated(false);
      navigate(`/sites/${siteName}/${siteId}`);
      return;
    }
    if (!user.emailVerified || error) {
      navigate('/home');
    }
  };

  const handleCreateSite = async () => {
    try {
      //Profanity filter
      const filter = new BadWordsFilter();
      if(filter.isProfane(siteName))
      {
        setMessage('Detected profanity in site name. Please refer to our Terms of Service.');
        setShowCreateSiteModal(false);
        setShowMessage(true);
        return;
      }

      const cleanSiteName = async () => {
        return siteName.replace(/\s/g, '').replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
      }

      const sn = await cleanSiteName();
      setSiteName(sn);

      if (sn.length < 3 || !/[a-zA-Z]/.test(sn)) {
        setMessage('Site names must be at least 3 characters long and include letters.');
        setShowCreateSiteModal(false);
        setShowMessage(true);
        return;
      }

      const response = await fetch(apiLink + 'createSite', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ siteName: sn, userId: user.uid, template }),
      });

      const data = await response.json();

      if (data.error) {
        setMessage(`Failed to create your site. Please try again later. (${data.error})`);
        setShowCreateSiteModal(false);
        setShowMessage(true);
        return;
      }

      const sid = data.message.siteId;
      setSiteId(sid);
      setMessage(`Your site ${siteName} was created successfully.`);
      setSiteCreated(true);
      setShowMessage(true);
      setShowCreateSiteModal(false);
    } catch (error) {
      setMessage('Failed to create your sites. Please try again later. (site/create-failed)');
      setError(true);
      setShowCreateSiteModal(false);
      setShowMessage(true);
    }
  };

  const isCreateSiteButtonDisabled = template === '';

  return (
    <div className="flex flex-col justify-center items-center h-screen text-white">
      <Helmet>
        <title>Sites - Stenz Digital</title>
      </Helmet>
      {showMessage && (
        <Message
          message={message}
          onClose={handleCloseMessage}
        />
      )}
      {!showMessage && (
        <div className="bg-gray-900 rounded-lg shadow-lg mt-12 lg:mt-0 p-8 text-white w-full md:w-3/4 lg:w-1/2 xl:w-2/3 overflow-y-auto max-h-screen overflow-x-hidden">
          <header className="text-3xl font-semibold text-gray-300 mb-4">Your Sites</header>
          {loading ? (
            <div className="mb-4">
              <div className="animate-pulse bg-gray-600 p-4 rounded-lg mb-2" style={{ width: '200px', height: '20px' }}></div>
              <div className="animate-pulse bg-gray-600 p-4 rounded-lg mb-2" style={{ width: '300px', height: '20px' }}></div>
            </div>
          ) : (
            <>
              <div className="mb-4">
                <p className='font-bold underline hover:text-blue-500 cursor-pointer' onClick={() => setShowCreateSiteModal(true)}>Create New</p>
                <p className="text-xl text-gray-400">Here are the sites you own:</p>
              </div>
              {user && userSites && userSites.length > 0 ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 overflow-y-auto max-h-96">
                  {userSites.map((site) => (
                    <div key={site.siteId} className="border-2 border-gray-600 rounded-lg p-5 truncate">
                      <Link className='text-gray-300 cursor-pointer hover:text-blue-500' to={`/sites/${site.name}/${site.siteId}`}>
                        {site.customDomain ? site.customDomain.replace('https://', '') : site.name}
                      </Link>
                      <p className="text-gray-400 truncate">{site.template}</p>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-gray-400">You have no sites.</p>
              )}
              <div className="p-4 flex items-center">
                <div className="bg-gray-600 p-4 rounded-lg text-gray-300 cursor-pointer hover:bg-gray-700" onClick={() => setShowCreateSiteModal(true)}>
                  <BsPlus size={50} />
                </div>
              </div>
            </>
          )}
        </div>
      )}

{showCreateSiteModal && (
  <div className="fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50">
    <div className="bg-gray-800 rounded-lg p-8 text-white w-96">
      <h2 className="text-2xl font-semibold mb-4">Create New Site</h2>
      <form>
        <div className="mb-4">
          <label htmlFor="siteName" className="block text-sm font-medium mb-2">Site Name</label>
          <input
            type="text"
            id="siteName"
            value={siteName}
            onChange={(e) => setSiteName(e.target.value)}
            className="bg-gray-700 rounded-lg w-full py-2 px-4 text-white placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
            placeholder="Enter site name"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="template" className="block text-sm font-medium mb-2">Template</label>
          <select
            id="template"
            value={template}
            onChange={(e) => setTemplate(e.target.value)}
            className="bg-gray-700 rounded-lg w-full py-2 px-4 text-white placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
            required
          >
            <option value="" disabled>Select a template</option>
            <option value="landing-page">Landing Page</option>
            <option value="e-commerce">E-commerce</option>
          </select>
        </div>
        <div className="flex justify-end">
          <button type="button" onClick={() => setShowCreateSiteModal(false)} className="mr-4 bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-blue-300">Cancel</button>
          <button type="button" onClick={handleCreateSite} disabled={isCreateSiteButtonDisabled} className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-blue-300">Create Site</button>
        </div>
      </form>
    </div>
  </div>
)}
    </div>
  );
};

export default Sites;