import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, Link, useNavigate } from 'react-router-dom';
import useAuthentication from '../authenticate';
import Message from './Message';

const getStatusLink = process.env.REACT_APP_SITE_EDITOR_LINK
const getOnboardingLink = process.env.REACT_APP_ONBOARDING_LINK
const getLoginLink = process.env.REACT_APP_GET_LOGIN_LINK
const getAPI = process.env.REACT_APP_API_LINK


const SiteDashboard = () => {
  const user = useAuthentication();
  const { sn, sid } = useParams();
  const [loading, setLoading] = useState(true);
  const [siteData, setSiteData] = useState(null);
  const [customDomain, setCustomDomain] = useState('');
  const [onboardingId, setOnboardingId] = useState('');
  const [showShippingModal, setShowShippingModal] = useState(false);
  const [shippingCreated, setShippingCreated] = useState(false);
  const [shippingEnabled, setShippingEnabled] = useState(false);
  const [shippingCost, setShippingCost] = useState(null);
  const [minDel, setMinDel] = useState(null);
  const [maxDel, setMaxDel] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {

    const fetchUserSites = async () => {
      try {
        if (!user || !user.user || !user.user.sites) {
          return;
        }
    
        const sites = user.user.sites;
        const siteFound = sites.find(site => site.siteId === sid);
        const template = siteFound?.template;
        const shipping = siteFound?.shipping;
          
        if (!siteFound || !template || template !== 'e-commerce') {
          navigate(`/sites/${sn}/${sid}`);
        }

        if (siteFound && siteFound?.customDomain)
        {
          setCustomDomain(siteFound?.customDomain);
        }

        if (user.user.stripeAccountId)
        {
          setOnboardingId(user.user.stripeAccountId);
        }

        if (shipping)
        {
          setShippingCreated(true);
          setShippingEnabled(shipping.enabled);
          setShippingCost(shipping.rate);
          setMinDel(shipping.delv_min);
          setMaxDel(shipping.delv_max);
          console.log(shipping);
        }

      } catch (error) {
        console.error(error);
        navigate(`/sites/${sn}/${sid}`);
      }
    };

    const fetchData = async () => {
      try {
        if (!user || !user.user || !user.user.sites) {
          return;
        }
    
        const sites = user.user.sites;
        const siteFound = sites.find(site => site.siteId === sid);
        
        if(!siteFound?.hosted)
        {
          navigate(`/sites/${sn}/${sid}`);
        }

        const response = await fetch(getStatusLink + 'getStatus', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ siteId: sid, userId: user.uid }),
        });
        if (!response.ok) {
          navigate(`/sites/${sn}/${sid}`);
          return;
        }
        const data = await response.json();
        if (data.status && sn === siteFound.name) {
          setSiteData(data);
          await fetchUserSites();
          setLoading(false);
        } else {
          navigate(`/sites/${sn}/${sid}`);
        }
      } catch (error) {
        navigate(`/sites/${sn}/${sid}`);
      }
    };

    // Fetch site data initially
    fetchData();

  }, [sid, sn, navigate, user]);

  const handleEditSite = async () => {
    navigate(`/sites/${sn}/${sid}/edit`);
  }

  const handleViewProducts = async () => {
    navigate(`/sites/${sn}/${sid}/dashboard/products`);
  }

  const handleViewSite = async () => {
    if(siteData.status === 'Published')
    {
      if(customDomain)
      {
        window.open(customDomain, '_blank');
        return;
      }
      window.open(`https://${sn}.stnz.app`, '_blank');
    }
  }

  const handleOnboarding = async () => {
    try {
      const response = await fetch(getOnboardingLink, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: user.uid, sUrl: `${sn}/${sid}/dashboard` }),
      });
      const data = await response.json();
      if (data.url) {
        window.location.replace(data.url);
      } else {
        console.error('Failed to initiate onboarding...');
        setMessage(`Failed to initiate onboarding. (${data})`)
        setShowMessage(true);
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage(`Failed to initiate onboarding. (onboarding/internal-error)`)
      setShowMessage(true);
    }
  };

  const handleLogin = async () => {
    try {
      const response = await fetch(getLoginLink, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: user.uid }),
      });
      const data = await response.json();
      if (data.url) {
        window.open(data.url, '_blank');
      } else {
        setMessage(`Failed to initiate login. (${data})`)
        setShowMessage(true);
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage(`Failed to initiate login. (login-link/internal-error)`)
      setShowMessage(true);
    }
  };

  const handleShipping = async () => {
    try {
    setShowShippingModal(false);
      const response = await fetch(getAPI + (shippingCreated ? 'updateShipping' : 'createShipping'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: user.uid, siteId: sid, enabled: shippingEnabled, rate: shippingCost, delv_min: minDel, delv_max: maxDel}),
      });

      const data = await response.json();

      if (data.error)
      {
        setMessage(`Failed to ${shippingCreated ? 'update' : 'create'} shipping. (${data.error})`)
        setShowMessage(true);
      }

      if (response.ok)
      {
        setMessage(`Successfully ${shippingCreated ? 'updated' : 'created'} shipping!`)
        setShowMessage(true);
      }

    } catch (error) {
      console.error('Error:', error);
      setMessage(`Failed to ${shippingCreated ? 'update' : 'create'} shipping. (shipping/internal-error)`)
      setShowMessage(true);
    }
  }

  const handleCloseMessage = () => {
    setShowMessage(false);
  };

  return (
    <div className={`flex flex-col justify-center items-center min-h-screen p-4 sm:p-6 lg:p-4 text-white mt-16 sm:mt-0`}>
      <Helmet>
        <title>{customDomain ? customDomain.replace('https://', '') : sn } - Stenz Digital</title>
      </Helmet>
      {showMessage ? (
        <Message
        message={message}
        onClose={handleCloseMessage}
        />
      ) : (
      <div className="bg-gray-900 rounded-lg shadow-lg p-8 text-white max-w-xl w-full">
        <header className="text-3xl font-semibold text-gray-300 mb-4">Dashboard</header>
        <div className="mb-4">
          {loading ?
            <div className="animate-pulse bg-gray-600 rounded-lg mb-2" style={{ width: '200px', height: '25px' }}>
            </div>  :
            <p className="text-xl text-gray-400">
            <Link
              target={siteData.status === 'Published' ? `_blank` : ''}
              to={siteData.status === 'Published' ? (customDomain ? customDomain : `https://${sn}.stnz.app`) : ''}
              className={`font-bold underline hover:text-blue-500 ${siteData.status !== 'Published' && 'cursor-not-allowed'}`}
            >
              {customDomain ? customDomain.replace('https://', '') : sn }
            </Link>
          </p>
          }
          <div className="flex flex-row md:items-center mb-4 text-xl text-gray-400">
            <p className='font-semibold'>Status:</p>
            {loading ? (
              <div className="ml-2 flex items-center">
                <div className="animate-pulse bg-gray-600 rounded-lg mb-2" style={{ width: '200px', height: '25px' }}></div>
              </div>
            ) : (
              <div className="ml-2 flex items-center">
                <p className={`${siteData.status === 'Published' ? 'text-green-400' : siteData.status === 'Unpublished' ? 'text-yellow-400' : 'text-red-400'}`}>
                  {siteData.status}
                </p>
              </div>
            )}
          </div>
          {!loading ? (
            <>
            <div className="grid grid-cols-2 gap-4">
              <div className="p-4">
                <div className={`bg-gray-600 p-4 rounded-lg text-gray-300 hover:bg-gray-700 cursor-pointer`} onClick={siteData.status === 'Published' || siteData.status === 'Unpublished' ? handleViewProducts : null}>Products</div>
              </div>
              <div className="p-4">
                <div className={`bg-gray-600 p-4 rounded-lg text-gray-300 hover:bg-gray-700 cursor-pointer`} onClick={siteData.status === 'Published' || siteData.status === 'Unpublished' ? handleEditSite : null}>Edit Site</div>
              </div>
              <div className="p-4">
                <div className={`bg-gray-600 p-4 rounded-lg text-gray-300 hover:bg-gray-700 ${siteData.status !== 'Published' ? 'cursor-not-allowed' : 'cursor-pointer'}`} onClick={siteData.status === 'Published' ? handleViewSite : null}>View Site</div>
              </div>
              <div className="p-4">
                <div className={`bg-gray-600 p-4 rounded-lg text-gray-300 hover:bg-gray-700 cursor-pointer`} onClick={siteData.status === 'Published' || siteData.status === 'Unpublished' ? (!onboardingId ? handleOnboarding : handleLogin) : null}>{onboardingId ? 'Manage Sales' : 'Start Selling'}</div>
              </div>
            </div>
            </>
          ) : (
            <>
              <div className="ml-2">
                <div className='pt-4'>
                  <div className="animate-pulse bg-gray-600 rounded-lg mb-2" style={{ width: '100px', height: '25px' }}></div>
                  <div className="animate-pulse bg-gray-600 rounded-lg mb-2" style={{ width: '200px', height: '25px' }}></div>
                </div>
                <div className='pt-4'>
                  <div className="animate-pulse bg-gray-600 rounded-lg mb-2" style={{ width: '100px', height: '25px' }}></div>
                  <div className="animate-pulse bg-gray-600 rounded-lg mb-2" style={{ width: '200px', height: '25px' }}></div>
                </div>
                <div className='pt-4'>
                  <div className="animate-pulse bg-gray-600 rounded-lg mb-2" style={{ width: '100px', height: '25px' }}></div>
                  <div className="animate-pulse bg-gray-600 rounded-lg mb-2" style={{ width: '200px', height: '25px' }}></div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="flex flex-row pt-2 justify-between">
          <Link to={`/sites/${sn}/${sid}`}>
            <p className="font-bold underline hover:text-blue-500">Back</p>
          </Link>
          <button onClick={() => setShowShippingModal(true)}>
            <p className="font-bold underline hover:text-blue-500">Shipping</p>
          </button>
        </div>
      </div>
      )}

      {showShippingModal && (
        <div className="fixed z-50 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center">
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-gray-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium text-white" id="modal-title">Shipping</h3>
                    <div className="mt-2">
                      <form>
                        <div className="flex items-center mb-4">
                          <input id="shippingEnabled" type="checkbox" checked={shippingEnabled} onChange={(e) => setShippingEnabled(e.target.checked)} className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded" />
                          <label htmlFor="shippingEnabled" className="ml-2 block text-sm text-gray-400">Enable Shipping</label>
                        </div>
                        {shippingEnabled && (
                          <>
                            <div className="mb-4">
                              <label htmlFor="shippingCost" className="block text-sm font-medium text-gray-300">Shipping Cost</label>
                              <input type="number" id="shippingCost" placeholder={49.99} value={shippingCost} onChange={(e) => setShippingCost(e.target.value)} className="mt-1 px-3 py-2 bg-gray-600 text-white rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300" />
                            </div>
                            <div className="mb-4">
                              <label htmlFor="minDel" className="block text-sm font-medium text-gray-300">Estimated Delivery (Minimum, business days)</label>
                              <input type="number" id="minDel" placeholder={3} value={minDel} onChange={(e) => setMinDel(e.target.value)} className="mt-1 px-3 py-2 bg-gray-600 text-white rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300" />
                            </div>
                            <div className="mb-4">
                              <label htmlFor="maxDel" className="block text-sm font-medium text-gray-300">Estimated Delivery (Maximum, business days)</label>
                              <input type="number" id="maxDel" placeholder={5} value={maxDel} onChange={(e) => setMaxDel(e.target.value)} className="mt-1 px-3 py-2 bg-gray-600 text-white rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300" />
                            </div>
                          </>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-800 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm" onClick={handleShipping}>Save</button>
                <button type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:w-auto sm:text-sm" onClick={() => setShowShippingModal(false)}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default SiteDashboard;
