import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white p-4 text-center">
      <div>
        <p className='font-semibold'>© {new Date().getFullYear()} Stenz Digital - All rights reserved.</p>
      </div>
      <br/>
      <div className='font-bold'>
        <p>Stenz Digital</p>
        <p>7850, Stoholm, Denmark</p>
        <p>CVR: DK44687151</p>
      </div>
      <div>
          <Link to={'/legal'} target='_blank' rel='noopener noreferrer' className='font-bold underline hover:text-blue-500'>
            <p>Legal</p>
          </Link>

          <Link to={'/contact'} target='_blank' rel='noopener noreferrer' className='font-bold underline hover:text-blue-500'>
            <p>Contact</p>
          </Link>
      </div>
      <div>
        <p className='font-semibold'>Made in Denmark</p>
      </div>
    </footer>
  );
};

export default Footer;
